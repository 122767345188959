.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.smaller-input {
  font-size: 10px;
  padding: 2px 5px;
}
.text-danger {
  font-size: 12px;
  font-weight: bold;
  padding: 2px 5px;
  color: red;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.25rem;
  margin-bottom: 20px;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 550px;
  overflow: auto;
}

.MuiCardHeader-root{
  border-bottom:2px solid #3363FF;
  background-color: #DBE2EA;
}
.Boxsearch{
  border-top: 1px dotted #999;
  border-bottom: 1px dotted #999;
  background-color:  #f5f5f5;
}
.table td, .table th{
  vertical-align: middle;
}
.table-responsive {
  height: 190px;
}

.MuiCardHeader-title {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold !important;
  line-height: 1.334;
  letter-spacing: 0em;
}

.card-border {
  border-color: transparent !important;
}

.mt-20 {
  margin-top: 20px;
}

.background-div {
  padding-top: 5px;
  background-color:lightgray;
}

.scrollable {
  overflow: auto;
}

.scrollable-x{
  overflow-x: auto;
}

.scrollable-y {
  overflow-y: auto;
}

.MuiPaper-root {
  background: #3363FF !important;
}

.MuiCardContent-root {
  background: #ffffff !important;
}