body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3363FF !important;  /* 0033A0 */
  height: 100vh;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  background-color: #3363FF !important;
  border: 1px solid #3363FF !important;
}

.bx-bg-blue {
  background-color: #3363FF !important;
}

.bx-bg-white {
  background-color: #fff !important;
}

.bx-bg-orange {
  background-color: #FF7E44 !important;
}

.btn-bx-blue {
  background-color: #0033A0 !important;
  border-color: #0033A0 !important;
  color: #fff !important;
}

.btn-bx-cian {
  background-color: #00B4DB !important;
  border-color: #00B4DB !important;
  color: #fff !important;
}

.btn-bx-orange {
  background-color: #FF7E44 !important;
  border-color: #FF7E44 !important;
  color: #fff !important;
}

.btn-bx-blue:hover, .btn-bx-blue:focus, .btn-bx-blue:active {
  background-color: #00B4DB !important; /* 39C0E0 */
  border-color: #00B4DB !important;
  color: #fff !important;
}

.btn-bx-orange:hover, .btn-bx-orange:focus, .btn-bx-orange:active {
  background-color: #FF702E !important;
  border-color: #FF702E !important;
  color: #fff !important;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.bx-border-blue {
  border: 2px solid #0033A0;
  border-radius: 5px;
}

.bx-border-cian {
  border: 2px solid #00B4DB;
  border-radius: 5px;
}

table.table {
  overflow-y: auto;
  height: 100%;
}

table, table tbody tr td {
  border-color: #00B4DB !important;
  font-size: 13px;
}

table thead, table thead tr th {
  background-color: #00B4DB !important;
  border-color: #00B4DB !important;
  color: #fff !important;
  text-align: center;
}

.table td, .table th {
  padding: 5px !important;
}

.hv300 {
  height: 300px;
}

.overflow-login {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.6;
  z-index: 999;
}

.overflow {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  margin-top: 0px;
  margin-left: 0px;
  background: #000;
  opacity: 0.6;
  z-index: 999;
}

.overflow-login .bx-spin, .overflow .bx-spin {
  fill: #39C0E0 !important;
  height: 20% !important;
  width: 20% !important;
  top: 30%;
  left: 40%;
  position: absolute;
}

.bx-uppercase, #bxUsuario, #bxPass {
  text-transform: uppercase;
}

.hplImj {
  background: #fff !important;
}

.bx-label {
  font-weight: bold;
}

.btn.xs {
  padding: 2px 6px 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}

.form-control.con-btn {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.w85 {
  min-width: 85px !important;
  width: 85px !important;
}

.w100 {
  min-width: 100px !important;
  width: 100px !important;
}

.w180 {
  min-width: 180px !important;
  width: 180px !important;
}

svg {
  margin-top: -3px;
}

.vh300 {
  max-height: 300px;
}